import { BREAKPOINTS } from "src/consts";
import { useWindowSize } from "src/utils/hooks";
import Checkbox2 from "src/icons/generic/Checkbox2";
import "./styles.scss";

const CommunityBanner = () => {
  const { width } = useWindowSize();
  const isDesktop = width >= BREAKPOINTS.desktop;

  return (
    <div className="community-banner">
      <svg
        width={243}
        height={179}
        viewBox="0 0 243 179"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.48279 110.18C23.8984 98.3142 40.4723 88.0097 40.4723 88.0097C40.4723 88.0097 44.6936 85.5743 57.7758 84.2451C73.2541 82.6787 81.1642 75.7344 81.1642 75.7344C80.4736 77.8222 82.8814 80.1318 85.954 80.2433C88.7183 80.3428 91.5456 79.1622 91.5456 79.1622C98.7039 82.7038 103.456 75.6578 103.456 75.6578C112.466 79.7711 115.367 61.7406 115.367 61.7406C115.367 61.7406 121.13 73.1972 126.573 69.0479C132.029 64.8974 126.697 53.1052 121.291 45.3388C117.193 39.4492 100.272 22.7982 76.3398 29.1228C55.4707 34.6352 41.4468 47.38 33.9085 53.1636C31.8934 54.7092 22.6096 58.7838 19.5174 60.5207C13.89 63.683 7.61054 67.0814 1 70.6667"
          stroke="#FF884D"
          strokeWidth="1.08634"
          strokeLinecap="round"
        />
        <path
          d="M103.126 76.1523C102.937 76.1523 102.75 76.0649 102.63 75.9009C102.451 75.6572 98.2727 70.4278 100.835 65.0837C100.985 64.7755 101.354 64.6454 101.659 64.7941C101.966 64.9438 102.096 65.3155 101.947 65.6248C99.7102 70.2857 103.582 75.1074 103.622 75.162C103.824 75.4375 103.767 75.8255 103.493 76.0299C103.382 76.1119 103.253 76.1523 103.126 76.1523Z"
          fill="#FF884D"
        />
        <path
          d="M91.0767 79.4219C90.865 79.4219 90.6587 79.3125 90.5447 79.1147C90.3938 78.8556 87.097 73.3667 90.3922 68.8719C90.5953 68.5964 90.9807 68.5385 91.2554 68.7407C91.529 68.9451 91.5876 69.3343 91.3857 69.6097C88.5682 73.453 91.5761 78.4304 91.6087 78.4862C91.7813 78.7824 91.6825 79.1628 91.3883 79.3366C91.2906 79.3934 91.1831 79.4219 91.0767 79.4219Z"
          fill="#FF884D"
        />
        <path
          d="M41.9308 87.8516C41.8645 87.8516 41.795 87.8406 41.7288 87.8166C38.0731 86.5388 34.8474 82.2134 34.7117 82.0287C34.5086 81.7543 34.5651 81.3652 34.8387 81.1597C35.1123 80.9553 35.4988 81.0121 35.7029 81.2876C35.7333 81.3302 38.8397 85.4916 42.1338 86.6426C42.4552 86.7552 42.6256 87.1093 42.5138 87.434C42.4259 87.6898 42.187 87.8516 41.9308 87.8516Z"
          fill="#FF884D"
        />
        <path
          d="M166.099 51.7644L166.099 51.7644C166.352 51.6961 166.554 51.5318 166.677 51.3172C166.799 51.1038 166.841 50.8436 166.772 50.5857C166.772 50.5857 166.772 50.5857 166.772 50.5857L153.617 1.22079L153.617 1.2206C153.542 0.939133 153.352 0.722069 153.112 0.60463C152.912 0.507416 152.681 0.479729 152.453 0.541374L152.453 0.541397L103.419 13.7864L103.419 13.7864C103.167 13.8547 102.965 14.0191 102.841 14.2329C102.718 14.4474 102.677 14.7074 102.746 14.965C102.746 14.965 102.746 14.965 102.746 14.9651L115.902 64.33L115.902 64.3302C115.955 64.5319 116.068 64.6992 116.214 64.821C116.445 65.0128 116.759 65.0912 117.066 65.0082L117.066 65.0082L166.099 51.7644Z"
          fill="#121212"
          stroke="white"
          strokeWidth="1.01137"
        />
        <mask
          id="mask0_364_27290"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x={102}
          y={0}
          width={66}
          height={66}
        >
          <path
            d="M166.233 52.2526L117.2 65.4964C116.727 65.6243 116.245 65.5019 115.893 65.21C115.668 65.0231 115.496 64.7662 115.415 64.4602L102.259 15.0952C102.155 14.7039 102.218 14.3071 102.405 13.9814C102.591 13.6567 102.901 13.4031 103.289 13.2982L152.323 0.0532074C152.674 -0.0418854 153.032 0.00186157 153.337 0.15052C153.703 0.329788 153.994 0.662086 154.108 1.09058L167.263 50.4555C167.367 50.8469 167.304 51.2436 167.118 51.5683C166.931 51.894 166.622 52.1476 166.233 52.2526Z"
            fill="#121212"
          />
        </mask>
        <g mask="url(#mask0_364_27290)">
          <g filter="url(#filter0_f_364_27290)">
            <ellipse cx="179.367" cy="49.9021" rx="20.4451" ry="18.2263" fill="#79BEFE" />
          </g>
        </g>
        <path
          d="M108.133 32.793L109.083 36.5227L116.428 64.0161C116.506 64.3067 116.805 64.4788 117.095 64.4002L129.398 61.0666L128.731 57.2776L120.416 41.416L108.133 32.793Z"
          fill="#121212"
        />
        <path
          d="M143.565 16.2341C143.464 16.2341 143.361 16.2472 143.26 16.2756L119.195 22.7762C118.872 22.8626 118.602 23.0856 118.452 23.3862C118.324 23.6441 118.294 23.9403 118.368 24.218L124.824 48.4463C124.91 48.7677 125.127 49.0387 125.42 49.1885C125.679 49.3219 125.978 49.3579 126.258 49.2792L150.323 42.7775C150.625 42.6966 150.878 42.5021 151.034 42.2299C151.19 41.9566 151.232 41.6396 151.15 41.3368L144.693 17.1085C144.613 16.8047 144.418 16.55 144.148 16.3926C143.968 16.2876 143.768 16.2341 143.565 16.2341ZM125.954 50.5625C125.576 50.5625 125.201 50.4718 124.858 50.2958C124.255 49.9853 123.809 49.4279 123.632 48.7687L117.176 24.5404C117.023 23.9666 117.085 23.3599 117.349 22.8297C117.655 22.2121 118.212 21.7542 118.875 21.5749L142.941 15.0743C143.561 14.907 144.21 14.9934 144.765 15.317C145.322 15.6394 145.719 16.1619 145.885 16.785L152.342 41.0133C152.509 41.6374 152.424 42.2889 152.102 42.8508C151.782 43.4104 151.263 43.8116 150.643 43.9788L126.578 50.4794C126.372 50.5341 126.163 50.5625 125.954 50.5625Z"
          fill="white"
        />
        <path
          d="M135.771 39.8125C135.378 39.8125 134.99 39.6857 134.668 39.4463L124.188 31.6253C123.367 31.012 123.195 29.8446 123.804 29.0171C124.413 28.1919 125.572 28.0181 126.393 28.6302L134.93 35.0007L145.998 6.59901C146.372 5.64146 147.446 5.16813 148.398 5.54524C149.35 5.92127 149.819 7.00233 149.446 7.96097L137.494 38.6287C137.283 39.1687 136.835 39.5796 136.281 39.7392C136.113 39.7884 135.941 39.8125 135.771 39.8125Z"
          fill="#34C759"
        />
        <path
          d="M113.118 61.2727L108.074 44.3191L112.228 43.7969L119.424 44.3191L124.691 45.5146L127.658 48.7261L128.103 52.1616C127.683 52.9334 126.249 54.9549 123.875 56.8668C121.501 58.7788 115.715 60.6007 113.118 61.2727Z"
          fill={isDesktop ? "#05080A" : "#0F181F"}
        />
        <path
          d="M109.002 43.9761C115.565 43.4639 127.183 43.0496 128.269 50.7012C128.967 55.6201 118.97 59.5158 113.812 61.1554"
          stroke="#FF884D"
          strokeWidth="1.19497"
        />
        <path
          d="M81.254 76.3594C81.0988 76.3594 80.9435 76.3014 80.823 76.1834C80.5787 75.9429 80.5743 75.5505 80.8121 75.3045C81.0379 75.0717 86.4515 69.5625 96.6705 65.9269C105.61 62.7482 113.739 60.5347 113.821 60.5129C114.147 60.4243 114.489 60.6178 114.578 60.9512C114.667 61.2824 114.471 61.6235 114.142 61.712C114.062 61.7339 105.974 63.9364 97.082 67.0998C87.1322 70.6381 81.7491 76.1167 81.6959 76.1714C81.5743 76.296 81.4147 76.3594 81.254 76.3594Z"
          fill="#FF884D"
        />
        <path
          d="M82.5715 48.4297C82.2935 48.4297 82.0406 48.2373 81.9722 47.9542C81.8929 47.6197 82.097 47.2841 82.4282 47.2043C93.6623 44.4869 109.269 43.3315 109.425 43.3195C109.758 43.2889 110.061 43.5523 110.085 43.8944C110.11 44.2366 109.854 44.5339 109.514 44.5602C109.359 44.5711 93.8513 45.7199 82.717 48.4122C82.6681 48.4231 82.6193 48.4297 82.5715 48.4297Z"
          fill="#FF884D"
        />
        <path
          d="M242.001 178C226.221 166.582 211.455 154.064 206.057 149.405C204.691 148.225 203.895 147.523 201.187 147.443C196.164 147.294 184.963 146.136 174.466 141.737C165.856 138.123 163.302 136.123 163.302 136.123C163.302 136.123 160.673 140.364 157.479 140.718C152.665 141.257 151.917 135.962 151.917 135.962C151.917 135.962 148.71 139.005 145.681 137.358C142.59 135.678 148.137 123.014 145.113 123.014C142.089 123.014 140.106 121.604 140.106 121.604C140.106 121.604 138.502 120.223 137.242 118.722C135.982 117.221 136.828 118.174 136.513 117.728C136.75 117.397 135.852 116.774 136.106 116.444C140.035 111.347 145.796 106.617 145.796 106.617C145.796 106.617 128.776 109.422 128.504 101.297C128.365 97.2973 137.301 92.0406 148.978 89.0095C161.047 85.8811 173.566 85.164 183.461 87.5054C201.187 95.0705 206.361 105.028 212.712 111.697C214.139 113.197 225.207 119.759 227.306 121.309C231.723 124.572 238.423 129.195 241.276 131.685"
          stroke="#FF884D"
          strokeWidth="1.08634"
          strokeLinecap="round"
        />
        <path
          d="M174.597 114.117C174.405 114.117 174.214 114.026 174.094 113.857C169.814 107.794 162.723 106.939 159.871 106.853C154.551 106.692 145.919 107.235 145.832 107.241C145.489 107.273 145.198 107.001 145.177 106.659C145.155 106.317 145.414 106.023 145.754 106.001C145.842 105.994 154.525 105.446 159.908 105.61C162.943 105.703 170.499 106.62 175.1 113.136C175.298 113.417 175.233 113.803 174.955 114.002C174.846 114.08 174.721 114.117 174.597 114.117Z"
          fill="#FF884D"
        />
        <path
          d="M156.831 112.336C156.592 112.336 156.365 112.196 156.264 111.962C156.128 111.646 156.272 111.281 156.584 111.144C156.882 111.015 163.936 107.985 171.257 109.582C171.59 109.655 171.801 109.986 171.729 110.32C171.658 110.657 171.328 110.87 170.997 110.796C164.056 109.293 157.145 112.255 157.076 112.285C156.996 112.318 156.912 112.336 156.831 112.336Z"
          fill="#FF884D"
        />
        <path
          d="M202.921 148.168C202.698 148.168 202.482 148.046 202.372 147.832C202.216 147.528 202.333 147.154 202.636 146.995C205.347 145.578 207.696 142.906 207.72 142.88C207.945 142.622 208.335 142.598 208.591 142.823C208.847 143.049 208.872 143.441 208.648 143.699C208.547 143.816 206.125 146.572 203.205 148.097C203.113 148.146 203.017 148.168 202.921 148.168Z"
          fill="#FF884D"
        />
        <path
          d="M155.135 127.035L155.135 127.035C155.392 126.987 155.606 126.839 155.746 126.635L155.746 126.635C155.885 126.433 155.947 126.177 155.898 125.916L155.898 125.915L146.689 75.6522L146.689 75.6515C146.637 75.3658 146.465 75.1341 146.234 74.9967C146.043 74.8839 145.814 74.8377 145.582 74.8809L145.582 74.881L95.6571 84.1523C95.6568 84.1524 95.6565 84.1524 95.6562 84.1525C95.3983 84.2013 95.1841 84.349 95.0452 84.5518C94.9067 84.7556 94.845 85.0115 94.8929 85.2742C94.8929 85.2742 94.8929 85.2742 94.8929 85.2743L104.102 135.535C104.102 135.536 104.102 135.536 104.102 135.536C104.14 135.742 104.239 135.918 104.376 136.051L104.376 136.052C104.591 136.261 104.896 136.365 105.21 136.306C105.21 136.306 105.21 136.306 105.211 136.306L155.135 127.035Z"
          fill="#121212"
          stroke="white"
          strokeWidth="1.01137"
        />
        <mask
          id="mask1_364_27290"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x={94}
          y={74}
          width={63}
          height={63}
        >
          <path
            d="M155.233 127.528L105.309 136.8C104.825 136.889 104.355 136.729 104.029 136.409C103.819 136.205 103.668 135.935 103.611 135.623L94.4012 85.3611C94.3285 84.9621 94.423 84.5718 94.6336 84.2625C94.8453 83.9532 95.1743 83.7258 95.5695 83.6515L145.495 74.3799C145.853 74.3132 146.206 74.3853 146.498 74.5581C146.849 74.7668 147.113 75.121 147.192 75.5571L156.401 125.82C156.475 126.217 156.381 126.609 156.169 126.917C155.957 127.226 155.628 127.455 155.233 127.528Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_364_27290)">
          <g filter="url(#filter1_f_364_27290)">
            <ellipse cx="100.486" cy="133.351" rx="20.4451" ry="18.2263" fill="#79BEFE" />
          </g>
        </g>
        <path
          d="M148.553 88.6289L137.979 91.8323L141.132 109.295H142.852L152.653 111.174L148.553 88.6289Z"
          fill="#121212"
        />
        <path
          d="M135.392 89.8072C135.321 89.8072 135.249 89.8138 135.178 89.8269L110.675 94.3774C110.346 94.4386 110.059 94.6387 109.885 94.9261C109.738 95.1743 109.684 95.465 109.737 95.7492L114.256 120.418C114.317 120.749 114.507 121.029 114.792 121.205C115.039 121.359 115.335 121.412 115.619 121.364L140.123 116.812C140.43 116.756 140.696 116.581 140.873 116.322C141.051 116.063 141.118 115.749 141.06 115.44L136.54 90.7713C136.484 90.462 136.311 90.1942 136.054 90.016C135.856 89.8783 135.626 89.8072 135.392 89.8072ZM115.405 122.625C114.962 122.625 114.526 122.501 114.142 122.262C113.567 121.906 113.165 121.314 113.043 120.643L108.523 95.9744C108.415 95.3907 108.525 94.7906 108.83 94.2834C109.185 93.692 109.776 93.281 110.451 93.1543L134.954 88.6037C135.585 88.49 136.223 88.6256 136.753 88.9918C137.283 89.3579 137.638 89.9099 137.754 90.5461L142.274 115.215C142.391 115.85 142.254 116.494 141.89 117.026C141.527 117.559 140.979 117.917 140.347 118.035L115.844 122.586C115.697 122.612 115.552 122.625 115.405 122.625Z"
          fill="white"
        />
        <path
          d="M151.994 136.488C151.919 136.488 151.842 136.474 151.768 136.446C151.451 136.319 151.295 135.959 151.42 135.64C152.606 132.602 153.569 131.52 153.609 131.452C153.784 131.158 154.163 131.059 154.455 131.239C154.747 131.415 154.842 131.796 154.667 132.091C154.658 132.106 153.713 133.163 152.569 136.094C152.473 136.339 152.24 136.488 151.994 136.488Z"
          fill="#FF884D"
        />
        <path
          d="M136.91 92.75L139.638 107.661L135.482 108.975L128.131 104.494L132.634 94.7302L136.91 92.75Z"
          fill="#121212"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M131.779 93.6992C130.848 93.2134 129.703 93.5768 129.222 94.5111L125.034 102.652L117.256 97.9117C116.355 97.3627 115.184 97.6504 114.641 98.5541C114.097 99.4579 114.387 100.636 115.288 101.185L123.282 106.057L118.978 114.423C118.497 115.357 118.863 116.508 119.795 116.994C120.727 117.48 121.872 117.117 122.352 116.182L126.54 108.042L134.317 112.782C135.218 113.331 136.389 113.043 136.932 112.14C137.476 111.236 137.186 110.058 136.285 109.509L128.292 104.638L132.596 96.2706C133.077 95.3364 132.711 94.1851 131.779 93.6992Z"
          fill="#FF3B30"
        />
        <path
          d="M187.65 110.276C187.65 110.276 176.062 114.172 167.103 113.542C158.143 112.911 143.51 108.929 143.51 108.929C138.002 107.301 133.029 113.845 137.087 118.629C142.111 124.553 161.962 136.447 172.296 140.73C172.296 140.73 179.293 129.905 187.65 110.276Z"
          fill={isDesktop ? "#05080A" : "#0F171F"}
        />
        <path
          d="M172.486 140.886C161.848 135.803 140.109 123.926 136.637 118.031C134.639 114.638 135.551 112.236 137.276 110.673C139.636 108.533 143.171 108.99 146.267 109.738C157.59 112.476 175.362 115.44 181.913 111.518"
          stroke="#FF884D"
          strokeWidth="1.08634"
          strokeLinecap="round"
        />
        <defs>
          <filter
            id="filter0_f_364_27290"
            x="114.422"
            y="-12.8245"
            width="129.891"
            height="125.454"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="22.2501" result="effect1_foregroundBlur_364_27290" />
          </filter>
          <filter
            id="filter1_f_364_27290"
            x="35.5408"
            y="70.6248"
            width="129.891"
            height="125.454"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="22.2501" result="effect1_foregroundBlur_364_27290" />
          </filter>
        </defs>
      </svg>

      <div className="community-banner-text">
        <h3 className="community-banner-text-title">Shape the future with your voice!</h3>

        <p className="community-banner-text-description">
          Be part of our community&apos;s evolution—your voice matters! Join us in voting on key
          decisions that will drive innovation, growth, and success. Together, let&apos;s build a
          future we can all believe in.
        </p>
      </div>

      <a
        className="community-banner-btn"
        href="https://www.tally.xyz/gov/wormhole"
        target="_blank"
        rel="noreferrer"
      >
        <Checkbox2 /> Vote now!
      </a>
    </div>
  );
};

export default CommunityBanner;
